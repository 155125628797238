<template>
	<b-form @submit.prevent="handleSubmit" style="padding-top: 15px">
		<b-row>
			<b-col cols="6">
				<fieldset class="scheduler-border">
					<div style="padding-top: 13px">
						<b-form-group :label="FormMSG(1, 'N°')" label-for="offer-number" :label-cols="3" :horizontal="true">
							<b-input v-model="dataToEdit.offerNumber" id="offer-number" type="text" :placeholder="FormMSG(2, 'Number...')" disabled />
						</b-form-group>
						<b-form-group :label="FormMSG(3, 'N° order')" label-for="offer-order" :label-cols="3" :horizontal="true">
							<b-input v-model="dataToEdit.orderNumber" id="offer-order" type="text" :placeholder="FormMSG(11, 'Order Number...')" disabled />
						</b-form-group>
						<b-form-group :label="FormMSG(6, 'Name')" label-for="offer-name" :label-cols="3" :horizontal="true">
							<b-input
								v-model="$v.dataToEdit.name.$model"
								id="offer-name"
								type="text"
								:placeholder="FormMSG(7, 'Offer name')"
								:disabled="task.sendStatus === 5"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(8, 'Offer description')" label-for="offer-description" :label-cols="3" :horizontal="true">
							<b-form-textarea
								v-model="$v.dataToEdit.description.$model"
								id="offer-description"
								type="text"
								:placeholder="FormMSG(9, 'Offer description...')"
								rows="16"
								:disabled="task.sendStatus === 5"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(18, 'Customer')" label-for="offer-customer" :label-cols="3" :horizontal="true">
							<customer-selector
								:client-id="dataToEdit.clientId"
								:location-id="dataToEdit.locationId"
								@client:selected="selectCustomer"
								@client-location:selected="selectLocation"
								@client-location:removed="onLocationRemoved"
								:add-label="FormMSG(37, 'Add')"
								:update-label="FormMSG(76, 'Update')"
								:placeholder="FormMSG(32, 'Choose customer ...')"
								:location-placeholder="FormMSG(75, 'Choose location ...')"
								:disabled="task.sendStatus === 5"
								:can-do-add="task.sendStatus !== 5"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(35, 'Invoice address')" label-for="offer-invoice-address" :label-cols="3" :horizontal="true" class="mb-3">
							<div class="d-flex flex-row mt-0">
								<!-- <b-form-checkbox
                  v-model="isAddressClientUsed"
                  @input="handleInvoiceAddress"
                  size="lg"
                  button
                /> -->
								<b-button
									v-if="task.sendStatus !== 5"
									:pressed.sync="invoiceAddressToggle"
									@click="handleInvoiceAddress"
									style="height: 30px"
									:variant="`${isAddressClientUsed ? 'success' : 'danger'}`"
									size="md"
								>
									<i :class="`${isAddressClientUsed ? 'fa fa-check' : 'fa fa-trash'}`" aria-hidden="true"></i>
								</b-button>
								<div class="d-flex flex-row mt-1" style="width: 100%" v-if="!isAddressClientUsed">
									<div id="tooltip-invoice-address" class="col-1 text-warning" style="cursor: pointer">
										<i class="fa fa-map-marker" aria-hidden="true" style="font-size: 1.25rem"></i>
									</div>
									<div v-if="task.sendStatus !== 5" class="col-7" style="bottom: 5px">
										<div class="d-flex flex-row supplier-action-update" :class="{ has_two_btn: true }">
											<b-button @click="openEditInvoiceAddressrModal">
												<i class="fa fa-edit" />
											</b-button>
											<b-button block variant="outline-primary" @click="openEditInvoiceAddressrModal">
												{{ FormMSG(41, 'Update') }}
											</b-button>
										</div>
									</div>
									<b-popover target="tooltip-invoice-address" triggers="hover" placement="top" variant="info">
										<template #title>{{ editSupplierPayload.name }}</template>
										{{ getAddressFormater(editSupplierPayload.address) }}
									</b-popover>
								</div>
							</div>
							<div class="mt-1 alert alert-info" role="alert" style="color: rgba(0, 0, 0, 0.7)">
								{{
									FormMSG(
										36,
										`The client address will be used by default (i.e. when the box checked), but you can always use another address by unchecking the box.`
									)
								}}
							</div>
						</b-form-group>
						<b-form-group :label="FormMSG(26, 'Architect')" label-for="offer-architect" :label-cols="3" :horizontal="true" class="clearfix">
							<supplier-selector
								:title="`${!isArchitectUpdate ? FormMSG(53, 'Add an architect') : FormMSG(54, 'Update architect details')}`"
								:addlabel="FormMSG(28, 'Add')"
								:update-label="FormMSG(29, 'Update')"
								:placeholder="FormMSG(30, 'Choose architect ...')"
								:supplier-id="architectId"
								:show-default-label="false"
								:disabled="task.sendStatus === 5"
								type="architect"
								ref="refSupplierArchitect"
								@change="handleSupplierArchitect"
								@supplier:unselected="onRemoveSupplierArchitect"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(27, 'Syndic')" label-for="offer-syndic" :label-cols="3" :horizontal="true">
							<supplier-selector
								:title="`${!isSyndictUpdate ? FormMSG(55, 'Add a syndic') : FormMSG(56, 'Update syndic details')}`"
								:addlabel="FormMSG(31, 'Add')"
								:update-label="FormMSG(33, 'Update')"
								:placeholder="FormMSG(34, 'Choose syndic ...')"
								:supplier-id="syndicId"
								:show-default-label="false"
								:disabled="task.sendStatus === 5"
								type="syndic"
								ref="refSupplierSyndic"
								@change="handleSupplierSyndic"
								@supplier:unselected="onRemoveSupplierSyndic"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(10, 'Offer date')" label-for="offer-date" :label-cols="3" :horizontal="true">
							<b-datepicker
								v-model="dataToEdit.offerDate"
								id="offer-date"
								:placeholder="FormMSG(5, 'Offer date')"
								:disabled="task.sendStatus === 5"
							/>
						</b-form-group>
					</div>
				</fieldset>
			</b-col>
			<b-col cols="6">
				<fieldset class="scheduler-border">
					<div style="padding-top: 15px">
						<b-form-group :label="FormMSG(25, 'Deposit') + ' (%)'" label-for="offer-vat" :label-cols="3" :horizontal="true">
							<b-input
								v-model="dataToEdit.percentDeposit"
								id="offer-vat"
								type="number"
								step="0.01"
								min="0"
								placeholder="0"
								:disabled="task.sendStatus === 5"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(51, 'Price factor')" label-for="offer-factor" :label-cols="3" :horizontal="true">
							<b-input
								v-model="dataToEdit.internalPriceFactor"
								id="offer-factor"
								type="number"
								step="0.01"
								min="0.01"
								placeholder="0"
								:disabled="task.sendStatus === 5"
								@blur="validatePricefactor"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(24, 'Discount') + ' (%)'" label-for="offer-vat" :label-cols="3" :horizontal="true">
							<b-input
								v-model="dataToEdit.percentDiscount"
								id="offer-vat"
								type="number"
								step="0.01"
								min="0"
								placeholder="0"
								:disabled="task.sendStatus === 5"
								@update="setInit"
								@click="setInit"
								@focus="setInit"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(19, 'VAT') + ' (%)'" label-for="offer-vat" :label-cols="3" :horizontal="true">
							<b-input
								v-model="dataToEdit.vat"
								id="offer-vat"
								type="number"
								placeholder="0"
								step="0.01"
								min="0"
								:disabled="task.sendStatus === 5"
								@update="setInit"
								@click="setInit"
								@focus="setInit"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(21, 'Total budget')" label-for="offer-total-budget" :label-cols="3" :horizontal="true">
							<b-input v-model="totalBudgetFormatter" id="offer-total-budget" type="text" placeholder="0" disabled />
						</b-form-group>
						<b-form-group :label="FormMSG(22, 'Total budget TTC')" label-for="offer-total-budget-ttc" :label-cols="3" :horizontal="true">
							<b-input v-model="totalBudgetTtcFormatter" id="offer-total-budget-ttc" type="text" placeholder="0" disabled />
						</b-form-group>
					</div>
				</fieldset>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2" offset="10">
				<b-button
					type="submit"
					variant="primary"
					block
					class="float-right"
					:disabled="!$v.dataToEdit.name.required || !$v.dataToEdit.description.required || formBusy"
				>
					<b-spinner v-if="formBusy" label="Loading..." /> {{ FormMSG(4, 'Save') }}
				</b-button>
			</b-col>
		</b-row>
		<address-modal
			v-model="isAddressModalOpen"
			:editable="isCanModify"
			:current-search="currSearch"
			:focus-supplier="editSupplierPayload"
			@submit="handleAddressSubmit"
			@delete-supplier="handleDeleteSupplier"
			@input="handleCloseModal"
		/>
	</b-form>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import CustomerSelector from '@/components/customer/CustomerSelector';
import SupplierSelector from '@/components/SupplierSelector';
import { updatedTask } from '@/cruds/task.crud';
import { rendCurrency } from '~helpers';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import AddressModal from '@/components/Modals/SupplierModal';
import { getSupplier, addSupplier, addAddressSupplier, updateSupplier, deleteSupplier } from '@/cruds/suppliers.crud';
import { isNil, omit } from '~utils';
import { addressFormatter } from '@/shared/fomaters';

export default {
	name: 'FormOffer',

	components: {
		CustomerSelector,
		SupplierSelector,
		AddressModal
	},

	mixins: [languageMessages, validationMixin],

	props: {
		task: { type: Object, default: () => {}, required: true },
		totalMaterials: { type: Number, default: 0, required: true }
	},

	data() {
		return {
			dataToEdit: {
				name: '',
				description: '',
				clientId: 0,
				locationId: 0,
				architectId: 0,
				syndicId: 0,
				offerDate: '',
				percentDiscount: 0,
				percentDeposit: 0,
				vat: 0,
				totalBudget: 0,
				totalBudgetTtc: 0,
				offerNumber: '',
				orderNumber: '',
				internalPriceFactor: 1
			},
			formBusy: false,
			init: true,
			countMatAdded: 0,
			isAddressClientUsed: false,
			isCanModify: false,
			isAddressModalOpen: false,
			currSearch: '',
			editSupplierPayload: {},
			invoiceAddressToggle: false,
			oldInternalPriceFactor: 0,
			isArchitectUpdate: false,
			isSyndictUpdate: false
		};
	},

	computed: {
		totalBudgetFormatter() {
			return rendCurrency(this.dataToEdit.totalBudget);
		},
		totalBudgetTtcFormatter() {
			return rendCurrency(this.dataToEdit.totalBudgetTtc);
		},
		architectId() {
			// current supplier id of edit data
			this.isArchitectUpdate = true;

			if (isNil(this.dataToEdit) || parseInt(this.dataToEdit.architectId, 10) === 0) {
				this.isArchitectUpdate = false;

				return 0;
			}

			return this.dataToEdit.architectId;
		},
		syndicId() {
			// current supplier id of edit data
			this.isSyndictUpdate = true;

			if (isNil(this.dataToEdit) || parseInt(this.dataToEdit.syndicId, 10) === 0) {
				this.isSyndictUpdate = false;

				return 0;
			}

			return this.dataToEdit.syndicId;
		}
	},
	validations: {
		dataToEdit: {
			name: {
				required
			},
			description: {
				required
			}
		}
	},
	watch: {
		async task(val) {
			this.dataToEdit.name = val.name;
			this.dataToEdit.description = val.description;
			this.dataToEdit.clientId = parseInt(val.client.id);
			this.dataToEdit.offerDate = val.offerDate.split('T')[0];
			this.dataToEdit.locationId = val.locationId;
			this.dataToEdit.percentDiscount = parseFloat(val.percentDiscount);
			this.dataToEdit.percentDeposit = parseFloat(val.percentDeposit);
			this.dataToEdit.vat = parseFloat(val.vat);
			this.dataToEdit.architectId = val.architectId;
			this.dataToEdit.syndicId = val.syndicId;
			this.dataToEdit.invoiceId = val.invoiceId;
			this.dataToEdit.offerNumber = val.offerNumber;
			this.dataToEdit.orderNumber = val.orderNumber;
			// this.dataToEdit.totalBudget = val.totalBudget
			// this.dataToEdit.totalBudgetTtc = val.totalBudgetTtc
			// console.log('invoice address: ', this.dataToEdit.invoiceId)
			this.isAddressClientUsed = this.dataToEdit.invoiceId === 0;
			this.isCanModify = !this.isAddressClientUsed;
			// console.log('invoice address: ', this.dataToEdit.invoiceId)
			this.dataToEdit.internalPriceFactor = parseFloat(val.internalPriceFactor);
			this.oldInternalPriceFactor = this.dataToEdit.internalPriceFactor;
			if (this.dataToEdit.invoiceId > 0) {
				this.editSupplierPayload = val.invoice;
				// console.log({editSupplierPayload: this.editSupplierPayload})
			}
		},
		async totalMaterials(val) {
			/*await this.calculTotal(
        val,
        this.dataToEdit.percentDiscount,
        this.dataToEdit.vat
      )
      await updatedTask(this.task.id, {
        totalBudget: this.dataToEdit.totalBudget,
        totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
        taskPlanningId: this.task.taskPlanningId,
      })*/
			if (this.countMatAdded === 0 && this.init) {
				this.dataToEdit.totalBudget = parseFloat(val);
				this.calcTtc(this.dataToEdit.totalBudget);
				await updatedTask(this.task.id, {
					totalBudget: this.dataToEdit.totalBudget,
					totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
					taskPlanningId: this.task.taskPlanningId
				});
			} else if (this.countMatAdded > 0 && this.init) {
				this.dataToEdit.totalBudget = parseFloat(val);
				this.calcTtc(this.dataToEdit.totalBudget);
				await updatedTask(this.task.id, {
					totalBudget: this.dataToEdit.totalBudget,
					totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
					taskPlanningId: this.task.taskPlanningId
				});
			} else if (this.countMatAdded === 0 && !this.init) {
				this.dataToEdit.totalBudget = parseFloat(val);
				await updatedTask(this.task.id, {
					totalBudget: this.dataToEdit.totalBudget,
					totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
					taskPlanningId: this.task.taskPlanningId
				});
			} else if (this.countMatAdded > 0 && !this.init) {
				this.dataToEdit.totalBudget = parseFloat(val);
				await updatedTask(this.task.id, {
					totalBudget: this.dataToEdit.totalBudget,
					totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
					taskPlanningId: this.task.taskPlanningId
				});
			}

			this.countMatAdded += 1;
		},
		'dataToEdit.percentDiscount'(val, oldVal) {
			if (!this.init) {
				oldVal = oldVal === '' ? 0 : oldVal;
				oldVal = parseFloat(oldVal) / 100;
				const diffPercent = 1 - oldVal;
				const diffTotal = (this.dataToEdit.totalBudget * oldVal) / diffPercent;
				this.dataToEdit.totalBudget += diffTotal;
				this.dataToEdit.totalBudgetTtc += diffTotal;
				this.calculTotal(this.dataToEdit.totalBudget, val, 0);
			}
		},
		'dataToEdit.vat'(val) {
			if (!this.init) {
				let totalVat = 0;
				if (val !== '' && parseFloat(val) !== 0) {
					totalVat = (parseFloat(this.dataToEdit.totalBudget) * parseFloat(val)) / 100;
				}
				this.dataToEdit.totalBudgetTtc = this.dataToEdit.totalBudget + totalVat;
			}
		},
		'dataToEdit.totalBudget'(val) {
			if (!this.init) {
				let totalVat = 0;
				if (this.dataToEdit.vat !== '' && parseFloat(this.dataToEdit.vat) !== 0) {
					totalVat = (parseFloat(val) * parseFloat(this.dataToEdit.vat)) / 100;
				}

				this.dataToEdit.totalBudgetTtc = parseFloat(val) + totalVat;
			}
		}
	},

	methods: {
		setInit() {
			this.init = false;
		},
		calcTtc(amount) {
			let totalVat = 0;
			if (this.dataToEdit.vat !== '' && parseFloat(this.dataToEdit.vat) !== 0) {
				totalVat = (parseFloat(amount) * parseFloat(this.dataToEdit.vat)) / 100;
			}

			this.dataToEdit.totalBudgetTtc = parseFloat(amount) + totalVat;
		},
		calculTotal(amount, percentDiscount, vat) {
			amount = parseFloat(amount);
			percentDiscount = percentDiscount !== '' ? parseFloat(percentDiscount) : 0;
			vat = vat !== '' ? parseFloat(vat) : 0;
			const totalDiscount = (amount * percentDiscount) / 100;
			const totalVat = (amount * vat) / 100;
			const total = amount - totalDiscount;
			this.dataToEdit.totalBudget = total;
			this.dataToEdit.totalBudgetTtc = total + totalVat;
		},
		async handleSubmit() {
			this.formBusy = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			const input = {
				name: this.dataToEdit.name,
				description: this.dataToEdit.description,
				clientId: parseInt(this.dataToEdit.clientId),
				locationId: parseInt(this.dataToEdit.locationId),
				percentDiscount: parseFloat(this.dataToEdit.percentDiscount),
				percentDeposit: parseFloat(this.dataToEdit.percentDeposit),
				vat: parseFloat(this.dataToEdit.vat),
				offerDate: new Date(this.dataToEdit.offerDate).toISOString(),
				taskPlanningId: this.task.taskPlanningId,
				totalBudget: this.dataToEdit.totalBudget,
				totalBudgetTtc: this.dataToEdit.totalBudgetTtc,
				architectId: this.dataToEdit.architectId,
				syndicId: this.dataToEdit.syndicId,
				internalPriceFactor: parseFloat(this.dataToEdit.internalPriceFactor)
			};

			await updatedTask(this.task.id, input)
				.then((res) => {
					if (res) {
						input.locationId = res.locationId;
						input.addressId = res.addressId;
						input.clientId = res.clientId;
						this.$emit('form-offer:update-info', input);

						if (this.oldInternalPriceFactor !== this.dataToEdit.internalPriceFactor) {
							this.oldInternalPriceFactor = this.dataToEdit.internalPriceFactor;
							this.$emit('form-offer:refreshTreeView', true);
						} else {
							this.$emit('form-offer:refreshTreeView', false);
						}

						this.$toast({
							message: this.FormMSG(50, 'Offer updated successfully'),
							title: this.FormMSG(42, 'Success'),
							variant: 'success'
						});
					}
				})
				.finally(() => {
					this.formBusy = false;
				});
		},
		selectLocation(payload) {
			if (payload !== null) {
				const { id } = payload;
				this.dataToEdit.locationId = parseInt(id, 10);
			} else {
				this.dataToEdit.locationId = 0;
			}
		},
		selectCustomer(payload) {
			if (payload !== null) {
				const { id } = payload;
				this.dataToEdit.clientId = id;
			} else {
				this.dataToEdit.clientId = 0;
			}
		},
		async onLocationRemoved(payload) {
			this.dataToEdit.locationId = 0;
		},
		handleSupplierArchitect(supplier) {
			this.isArchitectUpdate = false;

			if (isNil(supplier)) return;

			this.isArchitectUpdate = true;
			const { id } = supplier;
			this.dataToEdit.architectId = parseInt(id, 10);
		},
		onRemoveSupplierArchitect() {
			this.isArchitectUpdate = false;
			this.dataToEdit.architectId = 0;
		},
		handleSupplierSyndic(supplier) {
			this.isSyndictUpdate = false;

			if (isNil(supplier)) return;

			this.isSyndictUpdate = true;
			const { id } = supplier;
			this.dataToEdit.syndicId = parseInt(id, 10);
		},
		onRemoveSupplierSyndic() {
			this.isSyndictUpdate = false;
			this.dataToEdit.syndicId = 0;
		},
		handleInvoiceAddress() {
			// console.log('handleInvoiceAddress: ', this.invoiceAddressToggle)
			if (this.dataToEdit.invoiceId === 0) {
				this.editSupplierPayload = {};
				this.currSearch = '';
				this.isAddressModalOpen = true;
			} else {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(46, 'This invoice address will be removed, and the client address will be used by default.'), {
						title: this.FormMSG(47, 'Are you sure to continue ?'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'success',
						cancelVariant: 'primary',
						okTitle: this.FormMSG(48, 'YES'),
						cancelTitle: this.FormMSG(49, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (r) => {
						if (!r) return;
						// update task
						await updatedTask(this.task.id, {
							invoiceId: 0,
							taskPlanningId: this.task.taskPlanningId
						}).then(() => {
							this.editSupplierPayload = {};
							this.isAddressClientUsed = true;
							this.dataToEdit.invoiceId = 0;
						});
					});
			}
		},
		async handleAddressSubmit(supplier) {
			let required = false;
			if (isNil(supplier.name) || supplier.name.trim() === '') {
				this.$toast({
					message: this.FormMSG(38, 'Name is required'),
					title: this.FormMSG(40, 'Error'),
					variant: 'danger'
				});

				required = true;
			} else if (isNil(supplier.address) || (!isNil(supplier.address) && (!('state' in supplier.address) || !('city' in supplier.address)))) {
				this.$toast({
					message: this.FormMSG(39, 'Address is required'),
					title: this.FormMSG(40, 'Error'),
					variant: 'danger'
				});

				required = true;
			}

			if (required) {
				if (this.dataToEdit.invoiceId === 0) {
					this.isAddressClientUsed = true;
				}
				return;
			}

			if (this.dataToEdit.invoiceId === 0) {
				// add supplier
				const { id } = await addSupplier({
					name: supplier.name,
					type: 12
				});

				this.dataToEdit.invoiceId = parseInt(id, 10);

				// add supplier
				await addAddressSupplier({
					id: this.dataToEdit.invoiceId,
					supplier: omit(['address'], supplier),
					address: supplier.address
				}).then(async (record) => {
					// update task
					await updatedTask(this.task.id, {
						invoiceId: this.dataToEdit.invoiceId,
						taskPlanningId: this.task.taskPlanningId
					}).then(() => {
						this.$toast({
							message: this.FormMSG(44, 'Invoice address added successfully'),
							title: this.FormMSG(42, 'Success'),
							variant: 'success'
						});
					});

					this.editSupplierPayload = record;
				});
			} else {
				await updateSupplier({
					id: this.dataToEdit.invoiceId,
					supplier: omit(['address'], supplier),
					address: supplier.address
				}).then(() => {
					this.$toast({
						message: this.FormMSG(45, 'Invoice address updated successfully'),
						title: this.FormMSG(42, 'Success'),
						variant: 'success'
					});
				});

				this.editSupplierPayload = supplier;
			}

			this.isAddressClientUsed = false;
		},
		async handleDeleteSupplier(payload) {
			// console.log('delete: ', id);
			await deleteSupplier(payload).then(async () => {
				// update task
				await updatedTask(this.task.id, {
					invoiceId: 0,
					taskPlanningId: this.task.taskPlanningId
				}).then(() => {
					this.currSearch = '';
					this.isAddressModalOpen = false;
					this.editSupplierPayload = {};
					this.isAddressClientUsed = true;
					this.dataToEdit.invoiceId = 0;
					this.$toast({
						message: this.FormMSG(43, 'Invoice address removed successfully'),
						title: this.FormMSG(42, 'Success'),
						variant: 'success'
					});
				});
			});
		},
		handleCloseModal(payload) {
			if (this.dataToEdit.invoiceId === 0) {
				this.isAddressClientUsed = true;
			}
		},
		openEditInvoiceAddressrModal() {
			this.isAddressModalOpen = true;
		},
		getAddressFormater(address) {
			return addressFormatter(address);
		},
		validatePricefactor(payload) {
			if (parseFloat(this.dataToEdit.internalPriceFactor) === 0) {
				this.dataToEdit.internalPriceFactor = '0.01';
			}
			// console.log('validatePricefactor: ', this.dataToEdit.internalPriceFactor);
		}
	}
};
</script>

<style lang="scss">
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}
.supplier-action-update {
	width: 100%;

	button {
		width: 100%;
	}

	&.has_two_btn {
		button {
			margin-left: 0;
			margin-right: 0;

			&:first-child {
				float: left;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				width: 42px;
			}
			&:last-child {
				float: right;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				width: calc(100% - 42px);
			}
		}
	}
}
</style>
